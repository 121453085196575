<template>
    <div class="container mt-5">
        <div class="row justify-content-left mt-5">
            <div class="col-md-8">
                <div class="card">
                    <div class="card-body">
                        <h2 class="mb-5">404! Page is not found.</h2>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
}
</script>
